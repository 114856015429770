import React from "react"
import Slider from "infinite-react-carousel"
import { Link, graphql } from "gatsby"

import Menu from "../../components/menu"

const DrinkPage = ({ data, location }) => {
  const content = []
  const { state = {} } = location
  // const { currentCategory, products, activeSlide } = state

  for (var i = data.allWpSlider.edges.length - 1; i >= 0; i--) {
    content.push(data.allWpSlider.edges[i])
  }

  var { currentCategory, products, activeSlide } = {
    activeSlide: 0,
    currentCategory: "",
    products: content,
  }

  if (state === null) {
    var { currentCategory, products, activeSlide } = {
      activeSlide: 0,
      currentCategory: "",
      products: content,
    }
  } else {
    var { currentCategory, products, activeSlide } = state
  }

  var category = currentCategory ? currentCategory.split("_") : ""
  var cat = ""
  for (var x = 0; x < category.length; x++) {
    cat = cat + category[x] + " "
  }

  console.log("CAT: ", cat)

  var j = 0
  while (j < activeSlide) {
    products.push(products.shift())
    j++
  }

  const settings = {
    adaptiveHeight: false,
    arrows: true,
    autoplay: false,
    duration: 300,
    initialSlide: false,
  }

  const setClassActive = "tasting"

  if (products) {
    return (
      <div>
        <Menu content={content} setClassActive={setClassActive}></Menu>
        <div className="back_button">
          <Link to={"/tasting"} state={{ content }}>
            All products / <span>{cat}</span>
          </Link>
        </div>

        <Slider {...settings}>
          {products.map((slide, i) => (
            <div className="single_slide slides" key={i}>
              <div className="wrapper">
                <div className="row">
                  {slide.node.slider_acf.productImage ? (
                    <div className="col col-6 slider_image">
                      <img
                        src={slide.node.slider_acf.productImage.sourceUrl}
                        alt=""
                      />
                    </div>
                  ) : (
                    <div className="col col-6 slider_image"></div>
                  )}
                  <div className="col col-6">
                    <div className="slider_content">
                      <h4 className="slide_sub-title">
                        {slide.node.slider_acf.subTitle}
                      </h4>
                      <h3 className="slide_title">{slide.node.title}</h3>
                      {slide.node.slider_acf.boxContent.nose &&
                      slide.node.slider_acf.boxContent.palate &&
                      slide.node.slider_acf.boxContent.finish ? (
                        <div className="box_content">
                          <p className="body--m">
                            <strong>Nose</strong> <br />
                            {slide.node.slider_acf.boxContent.nose}
                          </p>
                          <p className="body--m">
                            <strong>Palate</strong>
                            <br />
                            {slide.node.slider_acf.boxContent.palate}
                          </p>
                          <p className="body--m">
                            <strong>Finish</strong> <br />
                            {slide.node.slider_acf.boxContent.finish}
                          </p>
                        </div>
                      ) : (
                        ""
                      )}

                      <h2 className="slide_description">
                        {slide.node.slider_acf.description}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    )
  }

  return (
    <div>
      <Menu></Menu>
      <div className="back_button">
        No content.
        <Link to={"/"}>Home page</Link>
      </div>
    </div>
  )
}

export default DrinkPage

export const query = graphql`
  query {
    allWpSlider {
      edges {
        node {
          id
          slug
          title
          slider_acf {
            description
            title
            subTitle
            productImage {
              sourceUrl
            }
            category
            boxContent {
              finish
              nose
              palate
            }
          }
        }
      }
    }
  }
`
